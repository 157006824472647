.linkCard, .linkCard > * {
	cursor: pointer;
}

.linkCard {
	width: min(230px, calc(100vw * (230 / 390)));
	height: min(300px, calc(100vw * (300 / 390)));
	border-radius: min(22px, calc(100vw * (22 / 390)));
	background: var(--secondary-background);
	display: flex;
	box-shadow: 0px 0px min(10px, calc(100vw * (10 / 390))) 0px var(--secondary-background);;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding: min(10px, calc(100vw * (10 / 390)));
}

.linkCardThumbnail {
	width: min(230px, calc(100vw * (230 / 390)));
	height: min(129.375px, calc(100vw * (129.375 / 390)));
	border-radius: min(15px, calc(100vw * (15 / 390)));
}

.linkCardInfo {
	width: min(210px, calc(100vw * (210 / 390)));
	padding: 0px min(10px, calc(100vw * (10 / 390)));
	margin-top: min(15px, calc(100vw * (15 / 390)));
	margin-bottom: min(10px, calc(100vw * (10 / 390)));
	height: min(108px, calc(100vw * (108 / 390)));
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	overflow: hidden;
}

.linkCardTitle {
	font-size: min(19px, calc(100vw * (19 / 390)));
	font-weight: 500;
	text-align: start;
	margin-bottom: min(10px, calc(100vw * (10 / 390)));
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.linkCardDescription {
	text-align: start;
	font-size: min(15px, calc(100vw * (15 / 390)));
	font-weight: 500;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.linkCardButton {
	padding: min(8px, calc(100vw * (8 / 390))) min(15px, calc(100vw * (15 / 390)));
	border-radius: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: black;
	background: white;
	font-size: min(14px, calc(100vw * (14 / 390)));
	font-weight: 500;
	margin: 0px min(10px, calc(100vw * (10 / 390)));
}