.textBlock {
	display: flex;
	align-items: center;
	justify-content: center;
	width: calc(100vw - min(60px, calc(100vw * (60 / 390))) - min(36px, calc(100vw * (36 / 390))));
	min-height: min(40px, calc(100vw * (40 / 390)));
	border-radius: min(25px, calc(100vw * (25 / 390)));
	padding: min(15px, calc(100vw * (15 / 390))) min(18px, calc(100vw * (18 / 390)));
	margin: 0px auto min(35px, calc(100vw * (35 / 390))) auto;
	background: var(--secondary-background);;
	font-size: min(16px, calc(100vw * (16 / 390)));
	font-weight: normal;
	color: var(--primary-color);;
	line-height: 1.3;
}

.textBlockText{
	/* text-align: start; */
	text-align: center;
}

@media screen and (min-width: 770px) {
	.textBlock{
		width: calc(770px - 60px - 36px);
	}
}
