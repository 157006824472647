.footer, .footer > * {
	cursor: pointer;
}

.footer {
	display: flex;
	direction: ltr;
	align-items: center;
	justify-content: center;
	gap: min(10px, calc(100vw * (10 / 390)));
	height: min(50px, calc(100vw * (50 / 390)));
	border-radius: min(25px, calc(100vw * (25 / 390)));
	background: #000000;
	padding: 0px min(20px, calc(100vw * (20 / 390)));
	width: min(160px, calc(100vw * (160 / 390)));
	margin: 0px auto;
	margin-bottom: min(35px, calc(100vw * (35 / 390)));
}

.footerText {
	font-size: min(15px, calc(100vw * (15 / 390)));
	color: white;
	font-weight: normal;
	line-height: 1;
	height: min(12px, calc(100vw * (12 / 390)));
}

.footerLogo {
	background-image: url("../../Assets/NuhaLogo.svg");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	width: min(76px, calc(100vw * (76 / 390)));
	height: min(16.84px, calc(100vw * (16.84 / 390)));
}