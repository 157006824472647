:root {
  --primary-color: #ffffff;
  --secondary-color: var(--secondary-color);
  --primary-background: #113037;
  --secondary-background: #1d4953;
}

body {
  margin: 0;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-background);
  color: var(--primary-color);
  line-height: 1.3;

}