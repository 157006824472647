.homeContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.profileDetails {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

}

.profileThumbnail {
	width: 100vw;
	height: min(130px, calc(100vw * (130 / 390)));
}

.profilePhoto {
	width: min(113px, calc(100vw * (113 / 390)));
	height: min(113px, calc(100vw * (113 / 390)));
	border-radius: 100%;
	box-shadow: 0px 0px min(10px, calc(100vw * (10 / 390))) 0px rgba(255, 255, 255, 0.35);
	margin-bottom: min(20px, calc(100vw * (20 / 390)));
}

.withThumbnail {
	margin-top: max(-56.5px, calc(100vw * (-56.5 / 390)));
}

.noThumbnail {
	margin-top: min(30px, calc(100vw * (30 / 390)));
}

.profileName {
	font-size: min(24px, calc(100vw * (24 / 390)));
	font-weight: 500;
	margin-bottom: min(16px, calc(100vw * (16 / 390)));
	display: flex;
	align-items: center;
	justify-content: center;
	gap: min(8px, calc(100vw * (8 / 390)));
}

.blueVerification {
	background-image: url("../../Assets/BlueCheck.svg");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	width: min(15px, calc(100vw * (15 / 390)));
	height: min(15px, calc(100vw * (15 / 390)));
}

.profileBio {
	width: min(300px, calc(100vw * (300 / 390)));
	font-size: min(14px, calc(100vw * (14 / 390)));
	font-weight: 500;
	margin-bottom: min(30px, calc(100vw * (30 / 390)));
	line-height: 1.3;
}

.socialMediaIcons,
.socialMediaIcons>* {
	cursor: pointer;
}

.socialMediaIcons {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: min(310px, calc(100vw * (310 / 390)));
	gap: min(7px, calc(100vw * (7 / 390)));
}

.homeContent{
	margin-top: min(20px, calc(100vw * (20 / 390)));
}

.homeMenuContainer {
	width: 100%;
	margin-bottom: min(30px, calc(100vw * (30 / 390)));
}

.homeMenu,
.homeMenu>* {
	cursor: pointer;
}

.homeMenu {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: min(4px, calc(100vw * (4 / 390)));
}

.homeMenu>div {
	padding: min(6px, calc(100vw * (6 / 390))) min(16px, calc(100vw * (16 / 390)));
	border-radius: 35px;
	color: var(--primary-color);
	font-size: min(15px, calc(100vw * (15 / 390)));
	font-weight: 500;
}

.selected {
	background: var(--secondary-background);
}

.productsContainer {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: calc(100vw - min(60px, calc(100vw * (60 / 390))));
	gap: min(30px, calc(100vw * (30 / 390)));
	margin-bottom: min(35px, calc(100vw * (35 / 390)));
}

.blogsContainer {
	margin-bottom: min(35px, calc(100vw * (35 / 390)));
}

.desktopFooter {
	display: none;
}

@media screen and (min-width: 530px) {
	.profileDetails {
		align-items: flex-start;
		width: calc(100vw - 60px);
	}

	.profileThumbnail {
		display: none;
	}

	.profilePhoto {
		width: 100px;
		height: 100px;
	}

	.withThumbnail {
		margin-top: 50px;
	}

	.noThumbnail {
		margin-top: 50px;
	}

	.profileName {
		font-size: 30px;
		margin-bottom: 10px;
	}

	.profileBio {
		width: 100%;
		font-size: 16px;
		margin-bottom: 20px;
		text-align: start;
	}

	.socialMediaIcons {
		justify-content: flex-start;
		width: 100%;
	}

	.homeMenuContainer{
		width: calc(100vw - 60px);
		margin-right: auto;
		margin-left: auto;
	}

	.homeMenu {
		justify-content: flex-end;
		width: 100%;
	}

	.blogsContainer {
		margin-bottom: 20px;
	}
}

@media screen and (min-width: 590px) {
	.productsContainer {
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}
}

@media screen and (min-width: 770px) {
	.profileDetails {
		width: 710px;
	}

	.productsContainer {
		width: 710px;
	}

	.blogsContainer {
		width: 710px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 30px;
		row-gap: 10px;
	}

	.homeMenuContainer {
		width: 710px;
	}
}

@media screen and (min-width: 1200px) {
	.homeContainer {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: center;
		gap: calc(80/1200 * 100vw);
	}

	.homeContent {
		margin-top: 50px;
	}

	.profileDetailsContainer {
		width: calc(270/1200 * 100vw);
		height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		position: sticky;
		top: 0px;
	}

	.profileDetails {
		display: flex;
		width: calc(270/1200 *100vw);
	}

	.profileBio {
		width: calc(270/1200 *100vw);
	}

	.socialMediaIcons {
		width: calc(270/1200 *100vw);
	}

	.desktopFooter {
		display: flex;
	}

	.mobileFooter {
		display: none;
	}
}