.cardBlock, .cardBlock > * {
	cursor: pointer;
}

.cardBlock {
	width: calc(100vw - min(60px, calc(100vw * (60 / 390))) - min(20px, calc(100vw * (20 / 390))));
	border-radius: min(22px, calc(100vw * (22 / 390)));
	background: var(--secondary-background);
	display: flex;
	box-shadow: 0px 0px min(10px, calc(100vw * (10 / 390))) 0px var(--secondary-background);
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding: min(10px, calc(100vw * (10 / 390)));
	margin: 0px auto min(35px, calc(100vw * (35 / 390))) auto;
}

.cardBlockThumbnail {
	width: calc(100vw - min(60px, calc(100vw * (60 / 390))) - min(20px, calc(100vw * (20 / 390))));
	height: calc(9/16 * calc(100vw - min(60px, calc(100vw * (60 / 390))) - min(20px, calc(100vw * (20 / 390)))));
	border-radius: min(15px, calc(100vw * (15 / 390)));
}

.cardBlockInfo {
	width: 100%;
	padding: 0px min(10px, calc(100vw * (10 / 390)));
	margin-top: min(15px, calc(100vw * (15 / 390)));
	margin-bottom: min(14px, calc(100vw * (14 / 390)));
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	overflow: hidden;
}

.cardBlockTitle {
	font-size: min(19px, calc(100vw * (19 / 390)));
	font-weight: 500;
	text-align: start;
	margin-bottom: min(10px, calc(100vw * (10 / 390)));
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.cardBlockDescription {
	text-align: start;
	font-size: min(15px, calc(100vw * (15 / 390)));
	font-weight: 500;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.cardBlockButton {
	padding: min(8px, calc(100vw * (8 / 390))) min(15px, calc(100vw * (15 / 390)));
	border-radius: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: black;
	background: white;
	font-size: min(14px, calc(100vw * (14 / 390)));
	font-weight: 500;
	margin: 0px min(10px, calc(100vw * (10 / 390)));
}

@media screen and (min-width: 770px) {
	.cardBlock{
		width: 690px;
		margin: 0px;
		margin-bottom: 35px;
	}
	.cardBlockThumbnail {
		width: 690px;
		height: calc(690px * 9/16);
		border-radius: min(15px, calc(100vw * (15 / 390)));
	}
}