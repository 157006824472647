.saveContactContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	position: fixed;
	bottom: min(30px, calc(100vw * (30 / 390)));
}

.saveContact {
	width: min(230px, calc(100vw * (230 / 390)));
	height: min(55px, calc(100vw * (55 / 390)));
	border-radius: min(35px, calc(100vw * (35 / 390)));
	background: white;
	color: black;
	font-size: min(20px, calc(100vw * (20 / 390)));
	display: flex;
	align-items: center;
	justify-content: center;
	gap: min(10px, calc(100vw * (10 / 390)));
	box-shadow: 0px 0px min(25px, calc(100vw * (25 / 390))) 0px rgba(255, 255, 255, 0.35);
}

.saveContactIcon {
	background: url('../../Assets/saveContactIcon.svg');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	width: min(25px, calc(100vw * (25 / 390)));
	height: min(25px, calc(100vw * (25 / 390)));
}