.blogPost, .blogPost > * {
	cursor: pointer;
}

.blogPost {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: min(290px, calc(100vw * (290 / 390)));
	margin: 0 auto;
	width: min(290px, calc(100vw * (290 / 390)));
	padding: min(14px, calc(100vw * (14 / 390))) min(20px, calc(100vw * (20 / 390))) min(10px, calc(100vw * (10 / 390))) min(20px, calc(100vw * (20 / 390)));
	background: var(--secondary-background);;
	border-radius: min(22px, calc(100vw * (22 / 390)));
	box-shadow: 0px 0px min(10px, calc(100vw * (10 / 390))) 0px rgb(29, 73, 83, 0.7);
	margin-bottom: min(20px, calc(100vw * (20 / 390)));
}

.blogPostThumbnail {
	width: min(317px, calc(100vw * (317 / 390)));
	height: min(178.3125px, calc(100vw * (178.3125 / 390)));
	border-radius: min(22px, calc(100vw * (22 / 390)));
}

.blogPostInfo {
	width: min(290px, calc(100vw * (290 / 390)));
	padding: 0px min(10px, calc(100vw * (10 / 390)));
	margin-bottom: 0px;
	height: min(110px, calc(100vw * (110 / 390)));
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	overflow: hidden;
}

.blogPostTitle {
	font-size: min(19px, calc(100vw * (19 / 390)));
	font-weight: 500;
	text-align: start;
	margin-bottom: min(7px, calc(100vw * (7 / 390)));
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.blogPostDescription {
	text-align: start;
	font-size: min(15px, calc(100vw * (15 / 390)));
	font-weight: 500;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media screen and (min-width: 770px){
	.blogPost {
		margin: 0px;
		margin-bottom: 20px;
	}
}