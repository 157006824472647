.linkFolder {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100vw;
	height: 100%;
	margin-bottom: min(35px, calc(100vw * (35 / 390)));
	-ms-overflow-style: none;  
  scrollbar-width: none; 
}

.linkFolder::-webkit-scrollbar,
.linkFolder>* ::-webkit-scrollbar { display: none; width: 0; height: 0;} 

.linkFolderTitle {
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--secondary-color);
	font-size: min(19px, calc(100vw * (19 / 390)));
	font-weight: 500;
	margin: 0px min(40px, calc(100vw * (40 / 390))) min(18px, calc(100vw * (18 / 390))) min(40px, calc(100vw * (40 / 390)));
	gap: min(10px, calc(100vw * (10 / 390)));
}

.linkFolderContainer {
	width: calc(100vw - min(60px, calc(100vw * (60 / 390))));
	padding: 0px min(30px, calc(100vw * (30 / 390)));
	display: flex;
	justify-content: flex-start;
	align-items: center;
	overflow-x: scroll;
	-ms-overflow-style: none; 
  scrollbar-width: none; 
}

.linkFolderContainer ::-webkit-scrollbar { display: none; width: 0; height: 0;} 

.linkFolderContainerLinks {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	gap: min(14px, calc(100vw * (14 / 390)));
	-ms-overflow-style: none; 
  scrollbar-width: none; 
}

.linkFolderContainerLinks ::-webkit-scrollbar { display: none; width: 0; height: 0;} 

.linkFolderBlur{
	display: none;
}

@media screen and (min-width: 770px) {
	.linkFolder {
		width: 710px;
	}

	.BlurContainer{
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.linkFolderContainer {
		width: 710px;
		padding: 0px;
	}

	.linkFolderTitle {
		margin: 0px 10px 18px 10px;
	}

	.linkFolderBlur {
		position: absolute;
		width: 40px;
		height: 320px;
		z-index: 3;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.linkFolderBlur > *{
		cursor: pointer;
	}

	.leftBlur {
		left: -1px;
		background-image: linear-gradient(90deg, rgba(52, 54, 47, 0), var(--primary-background));
		rotate: 180deg;
	}

	.rightBlur {
		right: -1px;
		background-image: linear-gradient(90deg, rgba(52, 54, 47, 0), var(--primary-background));
	}
}