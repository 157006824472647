.icon {
	width: min(40px, calc(100vw * (40 / 390)));
	height: min(40px, calc(100vw * (40 / 390)));
	border-radius: 100%;
	background: var(--secondary-color);
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon > div {
	width: min(18px, calc(100vw * (18 / 390)));
	height: min(18px, calc(100vw * (18 / 390)));
}