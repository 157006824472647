.contactList {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: calc(100vw - min(80px, calc(100vw * (80 / 390))));
	border-radius: min(25px, calc(100vw * (25 / 390)));
	padding: min(20px, calc(100vw * (20 / 390))) min(10px, calc(100vw * (10 / 390))) min(15px, calc(100vw * (15 / 390))) min(10px, calc(100vw * (10 / 390)));
	margin: 0px auto min(35px, calc(100vw * (35 / 390))) auto;
	background: var(--secondary-background);;
	font-size: min(16px, calc(100vw * (16 / 390)));
	font-weight: normal;
	color: var(--primary-background);;
	line-height: 1.3;
	text-align: start;
}

.contactListTop {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: min(10px, calc(100vw * (10 / 390)));
	margin-bottom: min(25px, calc(100vw * (25 / 390)));
	width: 100%;
}

.contactListInfo {
	width: calc(100vw - min(128px, calc(100vw * (128 / 390))));
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: min(6px, calc(100vw * (6 / 390)));
}

.contactListTitle {
	font-size: min(19px, calc(100vw * (19 / 390)));
	font-weight: 500;
	color: var(--secondary-color);
}

.contactListDescription {
	margin-bottom: min(8px, calc(100vw * (8 / 390)));
	color: var(--secondary-color);
}

.ContactListInputsContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: min(13px, calc(100vw * (13 / 390)));
	margin-bottom: min(13px, calc(100vw * (13 / 390)));
}

.contactListInput {
	width: calc(100vw - min(110px, calc(100vw * (110 / 390))));
	height: min(50px, calc(100vw * (50 / 390)));
	border-radius: min(35px, calc(100vw * (35 / 390)));
	padding: 0px min(16px, calc(100vw * (16 / 390)));
	background: var(--primary-background);
	border: none;
	outline: none;
	color: var(--primary-color);
	font-weight: 600;
	font-size: min(15px, calc(100vw * (15 / 390)));
}

.contactListInput::placeholder {
	color: var(--primary-color);
}

.contactListButton, .contactListButton > * {
	cursor: pointer;
}

.contactListButton {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: min(10px, calc(100vw * (10 / 390)));
	width: calc(100vw - min(78px, calc(100vw * (78 / 390))));
	height: min(50px, calc(100vw * (50 / 390)));
	border-radius: min(35px, calc(100vw * (35 / 390)));
	background: var(--primary-background);
	border: none;
	outline: none;
	color: var(--primary-color);;
	font-weight: 600;
	font-size: min(15px, calc(100vw * (15 / 390)));
}

.contactListIcon {
	width: min(14px, calc(100vw * (14 / 390)));
	height: min(14px, calc(100vw * (14 / 390)));
	background-image: url("../../Assets/Arrow.svg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	transform: rotate(-45deg);
}

@media screen and (min-width: 420px) {
	.ContactListInputsContainer {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 13px;
		margin-bottom: 13px;
	}

	.contactListInput {
		width: calc((100vw - 78px - 13px)/2 - 36px);
		padding: 0px 18px;
	}
}

@media screen and (min-width: 660px) {
	.contactList {
		width: calc(100vw - 100px);
		padding: 20px 20px 15px 20px;
	}

	.contactListTop {
		width: 100%;
	}

	.contactListInfo {
		width: 100%;
	}

	.ContactListActions {
		display: flex;
		gap: 10px;
		width: 100%;
	}

	.ContactListInputsContainer {
		margin-bottom: 0px;
		gap: 10px;

	}

	.contactListInput {
		width: calc((100vw - 100px - 180px)/2);
		padding: 0px 15px;
	}

	.contactListButton {
		gap: 10px;
		width: calc(100vw * (100/660));
	}
}

@media screen and (min-width: 770px) {
	.contactList {
		width: 670px;
		padding: 20px 20px 15px 20px;
	}

	.contactListTop {
		width: 100%;
	}

	.contactListInfo {
		width: 100%;
	}

	.ContactListActions {
		display: flex;
		gap: 10px;
		width: 100%;
	}

	.ContactListInputsContainer {
		margin-bottom: 0px;
		gap: 10px;

	}

	.contactListInput {
		width: calc((670px - 180px)/2);
		padding: 0px 15px;
	}

	.contactListButton {
		gap: 10px;
		width: calc(770px * (100/660));
	}
	
}