.blogContainer {
	background: rgb(0, 0, 0, 0.6);
	width: 100%;
	position: fixed;
	top: 0px;
	z-index: 999;
	overflow: scroll;
	height: 100vh;
}

.blog {
	background: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: min(650px, calc(100vw * (374/ 390)));
	margin: 0px auto;
	margin-top: min(60px, calc(100vw * (8/ 390)));
	margin-bottom: min(60px, calc(100vw * (8/ 390)));
	border-radius: min(20px, calc(100vw * (20/ 390)));
	padding-bottom: min(30px, calc(100vw * (30/ 390)));

}

.blogTitle {
	width: min(555px, calc(100vw * (340/ 390)));
	font-size: min(30px, calc(100vw * (30/ 390)));
	font-weight: 500;
	margin-top: max(-40px, calc(100vw * (-40/ 390)));
	margin-bottom: min(25px, calc(100vw * (25/ 390)));
	color: black;
}

.blogThumbnail {
	width: min(650px, calc(100vw * (374/ 390)));
	height: min(calc(650px * 9 / 16), calc(100vw * 374 * 9 / 16 / 390));
	border-radius: min(20px, calc(100vw * (20 / 390))) min(20px, calc(100vw * (20 / 390))) 0px 0px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.blogTop {
	padding: min(20px, calc(100vw * (20/ 390)));;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: min(610px, calc(100vw * (334/ 390)));
}

.blogTop > div{
	background: #000;
	padding: 0px min(16px, calc(100vw * (16/ 390)));
	font-size: min(16px, calc(100vw * (16/ 390)));
	font-weight: 500;
	border-radius: 10px;
	height: min(40px, calc(100vw * (40/ 390)));
	display: flex;
	align-items: center;
	justify-content: center;
}

.blogClose{
	height: min(40px, calc(100vw * (40/ 390)));
	width:  min(40px, calc(100vw * (40/ 390)));
	padding: 0px !important;
	cursor: pointer;
}

.blogCloseIcon{
	width: min(12px, calc(100vw * (12/ 390)));
	height: min(12px, calc(100vw * (12/ 390)));
	background: url("../../Assets/blogCloseIcon.svg");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}
.blogBody {
	width: min(555px, calc(100vw * (340/ 390)));
	font-size: min(16px, calc(100vw * (16/ 390)));
	text-align: justify;
	color: black;
}