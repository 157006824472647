.App {
  text-align: center;
}

.noScroll{
  overflow: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: white; /* Adjust to your text color */
    -webkit-box-shadow: 0 0 0px 1000px #113037 inset; /* Adjust to your background color */
}